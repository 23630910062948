import { IoIosArrowDropup } from 'react-icons/io'
import '../sass/components/GoTopButton.scss'


const GoTopButton = () => {

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // add show on scroll
  window.addEventListener('scroll', () => {
    let scrollPosition = window.scrollY
   
    if (document.querySelector('.goTop')){
      if (scrollPosition > 0) {
        document.querySelector('.goTop').classList.add('active')
      } else {
        document.querySelector('.goTop').classList.remove('active')
      }
    }
  });

  return (
    <>
      <p className='btn goTop' onClick={handleClick}>
        <IoIosArrowDropup className='go-top-icon'/>
      </p>
    </>
  )
}

export default GoTopButton
