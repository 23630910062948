import '../sass/components/Alert.scss'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Toast from 'react-bootstrap/Toast'
import { ALERT_RESET } from '../redux/constants/alertConstants'
import { TiWarningOutline } from 'react-icons/ti'

const Alert = ({AlertState = ''}) => {

  const [show, setShow] = useState(AlertState);

  const dispatch = useDispatch()

  const onClose = () => {
    setShow(false)
    dispatch({ type: ALERT_RESET })
  }

  const errors = AlertState.message.errors || AlertState.message.data.errors

  return (
    <>
      <Toast
        className='toast-alert error'
        onClose={onClose}
        show={show}
        animation={true}
        delay={7000}
        autohide={true}
      >
        <Toast.Header>
          <strong className='mr-auto'>Error</strong>
        </Toast.Header>
        <Toast.Body>
          
            {errors.map((e) => (
              <p className='toast-error-msg'>
                <TiWarningOutline className='warning-icon' />
                {e.msg}
              </p>
            ))}
          
          
        </Toast.Body>
      </Toast>
    </>
  )
}

export default Alert