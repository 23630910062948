import { IoMdCog } from 'react-icons/io'
import { GiPokecog } from 'react-icons/gi'
import { FaCog } from 'react-icons/fa'
import '../sass/components/Loader.scss'

const Loader = () => {
  return (
    <span className='gears-loader mx-auto d-block'>
     <FaCog className='gear-small'/>
     <GiPokecog className='gear-mid'/>
     <IoMdCog className='gear-big'/>
    </span>
  )
}

export default Loader