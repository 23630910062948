import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { rootReducer } from './reducers/rootReducer'

const userInfoFromStorage = window.localStorage.getItem('USER_INFO')
  ? JSON.parse(window.localStorage.getItem('USER_INFO'))
  : null

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export { store }
