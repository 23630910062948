import { GET_PAGES_FAIL, GET_PAGES_REQUEST, GET_PAGES_SUCCESS, PAGE_UPDATE_FAIL, PAGE_UPDATE_REQUEST, PAGE_UPDATE_RESET, PAGE_UPDATE_SUCCESS } from "../constants/pageConstants"

export const getPagesInfoReducer = (state = { loading: true, pages: [] }, action) => {
  switch (action.type) {
    case GET_PAGES_REQUEST:
      return { 
        loading: true, 
        pages: [] 
      }
    case GET_PAGES_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
      }
    case GET_PAGES_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pageUpdateReducer = (state = { page: {} }, action) => {
  switch (action.type) {
    case PAGE_UPDATE_REQUEST:
      return { loading: true }
    case PAGE_UPDATE_SUCCESS:
      return { loading: false, success: true, page: action.payload }
    case PAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PAGE_UPDATE_RESET:
      return { page: {} }
    default:
      return state
  }
}