import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../../redux/actions/userActions.js'


const UserInfo = () => {
  const dispatch = useDispatch()

  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    if (userInfo && userInfo !== null && userInfo._id && !userInfo.name) {
      dispatch(getUserDetails())
    }
  }, [dispatch, userInfo])

  return <></>
}

export default UserInfo