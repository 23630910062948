import { IoMdCog } from 'react-icons/io'
import '../sass/components/Loader.scss'

const LoaderSmall = () => {
  return (
    <span className='gears-loader-small mx-auto'>
     <IoMdCog className='gear-big'/>
    </span>
  )
}

export default LoaderSmall