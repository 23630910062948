import { combineReducers } from 'redux'
import { userLoginReducer, userGoogleReducer, userRegisterReducer, sendInquiryReducer } from './userReducers'
import { getPagesInfoReducer, pageUpdateReducer } from './pageReducer'
import { getRepairInfoReducer, saveRepairReducer, repairDeleteReducer } from './repairReducer'
import { getPostsInfoReducer, savePostReducer, postDeleteReducer, getPostByIdReducer } from './postReducer'
import { alertReducer } from './alertReducer'

export const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  userGoogle: userGoogleReducer,
  userRegister: userRegisterReducer,
  pagesInfo: getPagesInfoReducer,
  pageUpdate: pageUpdateReducer,
  repairsInfo: getRepairInfoReducer,
  saveRepair: saveRepairReducer,
  deleteRepair: repairDeleteReducer,
  postsInfo: getPostsInfoReducer,
  postInfo: getPostByIdReducer,
  savePost: savePostReducer,
  deletePost: postDeleteReducer,
  inquiry: sendInquiryReducer,
  alert: alertReducer,
})


