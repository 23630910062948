import {
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_FAIL,
  GET_POST_RESET,
  POST_SAVE_REQUEST,
  POST_SAVE_SUCCESS,
  POST_SAVE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,
  POST_DELETE_RESET,
  POST_SAVE_RESET,
} from '../constants/postConstants'

export const getPostsInfoReducer = (
  state = { loading: true, posts: [] },
  action
) => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return {
        loading: true,
        posts: [],
      }
    case GET_POSTS_SUCCESS:
      return {
        loading: false,
        posts: action.payload.posts,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case GET_POSTS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const savePostReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case POST_SAVE_REQUEST:
      return { loading: true }
    case POST_SAVE_SUCCESS:
      return { loading: false, success: true, post: action.payload }
    case POST_SAVE_FAIL:
      return { loading: false, error: action.payload }
    case POST_SAVE_RESET:
      return { post: {} }
    default:
      return state
  }
}

export const postDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DELETE_REQUEST:
      return { loading: true }
    case POST_DELETE_SUCCESS:
      return { loading: false, success: true }
    case POST_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case POST_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const getPostByIdReducer = (state = { loading: false, postById: '' }, action) => {
  switch (action.type) {
    case GET_POST_REQUEST:
      return { loading: true, postById: '' }
    case GET_POST_SUCCESS:
      return { loading: false, success: true, postById: action.payload }
    case GET_POST_FAIL:
      return { loading: false, postById: '', error: action.payload }
    case GET_POST_RESET:
      return { loading: false, postById: '', error: '' }
    default:
      return state
  }
}
