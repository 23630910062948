export const GET_REPAIR_REQUEST = 'GET_REPAIR_REQUEST'
export const GET_REPAIR_SUCCESS = 'GET_REPAIR_SUCCESS'
export const GET_REPAIR_FAIL = 'GET_REPAIR_FAIL'
export const REPAIR_SAVE_REQUEST = 'REPAIR_SAVE_REQUEST'
export const REPAIR_SAVE_SUCCESS = 'REPAIR_SAVE_SUCCESS'
export const REPAIR_SAVE_FAIL = 'REPAIR_SAVE_FAIL'
export const REPAIR_SAVE_RESET = 'REPAIR_SAVE_RESET'
export const REPAIR_DELETE_REQUEST = 'REPAIR_DELETE_REQUEST'
export const REPAIR_DELETE_SUCCESS = 'REPAIR_DELETE_SUCCESS'
export const REPAIR_DELETE_FAIL = 'REPAIR_DELETE_FAIL'

export const ITEMS_PER_PAGE = 5
