import {
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_LOGIN_GOOGLE_REQUEST,
  USER_LOGIN_GOOGLE_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  INQUIRY_SUCCESS,
  INQUIRY_REQUEST,
  INQUIRY_RESET,
  INQUIRY_FAIL,
} from '../constants/userConstants'
import axios from 'axios'
import { ALERT_ACTIVE } from '../constants/alertConstants'

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users/login',
      { email, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
  
    window.localStorage.setItem('USER_INFO', JSON.stringify({_id: data._id, policy: data.policy}))
  } catch (error) {
    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const loginGoogle = (tokenId) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_GOOGLE_REQUEST,
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users/login/google',
      { tokenId },
      config
    )

    dispatch({
      type: USER_LOGIN_GOOGLE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_LOGIN_RESET,
    })

    window.localStorage.setItem('USER_INFO', JSON.stringify({_id: data._id, policy: data.policy}))
  } catch (error) {
    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response,
    })
  }
}

export const loginUserSecondFactor = (email, code) => async (dispatch) => {
  
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const headers = {
      headers: {
        'Content-type': 'application/json',
      },
    }
    const { data } = await axios.post(
      '/api/users/login/2fa',
      { email, code },
      headers
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })
  
    window.localStorage.setItem('USER_INFO', JSON.stringify({_id: data._id, policy: data.policy}))
  } catch (error) {
    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const registerUser = (email, token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/users/register',
      { email, token },
      config
    )

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    })

  } catch (error) {
    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const registerUserConfirm = (name, password, policy, userToken) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    })

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.post(
      `/api/users/register-confirm/${userToken}`,
      { name, password, policy },
      config
    )

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    })

    // Login user right after registration
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    window.localStorage.setItem('USER_INFO', JSON.stringify({_id: data._id, policy: data.policy}))
  } catch (error) {
    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const logout = () => (dispatch) => {
  // Clear storage
  window.localStorage.setItem('USER_INFO', JSON.stringify({
    policy: {
      cookies: 'accepted',
    },
  })
)

  dispatch({ type: USER_LOGOUT })
  document.location.href = '/login'
}

export const getUserDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })
  
    const headers = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.get('/api/users/profile', headers)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    // Clear storage
    window.localStorage.removeItem('USER_INFO')
    document.location.href = '/'

    dispatch({
      type: ALERT_ACTIVE,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const sendInquiry = (email, name, phone, inquiry, token) => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_REQUEST,
    })

    const headers = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/contacts/inquiry',
      { email, name, phone, inquiry, token },
      headers
    )

    dispatch({
      type: INQUIRY_SUCCESS,
      payload: data,
    })

  } catch (error) {
    dispatch({
      type: INQUIRY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}

export const clearInquiry = () => async (dispatch) => {
  try {
    dispatch({
      type: INQUIRY_RESET,
    })

  } catch (error) {
    dispatch({
      type: INQUIRY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.response,
    })
  }
}