import axios from 'axios'
import {
  GET_PAGES_FAIL,
  GET_PAGES_REQUEST,
  GET_PAGES_SUCCESS,
  PAGE_UPDATE_FAIL,
  PAGE_UPDATE_REQUEST,
  PAGE_UPDATE_SUCCESS,
} from '../constants/pageConstants'

export const getPagesInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PAGES_REQUEST,
    })

    const headers = {
      headers: {
        'Content-type': 'application/json',
      },
    }

    const { data } = await axios.get('/api/pages', headers)

    dispatch({
      type: GET_PAGES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_PAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response,
    })
  }
}

export const updatePagePartial = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PAGE_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    if (page.images) {
      //@START Upload image
      try {
        const headers = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        const formData = new FormData()
        const files = Object.keys(page.images)
        files.forEach((x) => {
          formData.append('image', page.images[x][0])
        })

        const { data } = await axios.post(
          '/api/upload/pages',
          formData,
          headers
        )
        // Add images path to page data
        const imagesForUpload = data.map((img) => {
          return '/' + img.path
        })

        // Create paths array with exact positions
        let imageArray = []
        files.forEach((x) => {
          if (page.images[x][0]) {
            const url = imagesForUpload.shift()
            imageArray.push(url)
          } else {
            imageArray.push('')
          }
        })

        page.images = imageArray
      } catch (error) {
        dispatch({
          type: PAGE_UPDATE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.response,
        })
      }
      //@END Upload image
    }

    const { data } = await axios.put(`/api/pages/${page._id}`, page, config)

    dispatch({
      type: PAGE_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PAGE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.response,
    })
  }
}
