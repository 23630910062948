export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_LOGIN_GOOGLE_REQUEST = 'USER_LOGIN_GOOGLE_REQUEST'
export const USER_LOGIN_GOOGLE_SUCCESS = 'USER_LOGIN_GOOGLE_SUCCESS'
export const USER_LOGIN_GOOGLE_FAIL = 'USER_LOGIN_GOOGLE_FAIL'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const INQUIRY_REQUEST = 'INQUIRY_REQUEST_REQUEST'
export const INQUIRY_SUCCESS = 'INQUIRY_SUCCESS'
export const INQUIRY_FAIL = 'INQUIRY_FAIL'
export const INQUIRY_RESET = 'INQUIRY_RESET'
