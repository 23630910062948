import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '../sass/components/Header.scss'
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { RiUserShared2Fill } from 'react-icons/ri'
import { BsShieldShaded } from 'react-icons/bs'
import {
  FaSignOutAlt,
  FaCog,
  FaUserCircle,
  FaMapMarkedAlt,
  FaPhoneAlt,
} from 'react-icons/fa'
import { logout } from '../redux/actions/userActions'
import LoaderSmall from '../components/LoaderSmall'
import SliceText from '../components/SliceText'
import { routes } from '../helpers/appConstants'

const Header = () => {
  const { userInfo } = useSelector((state) => state.userLogin)
  const { userInfo: userGoogleInfo } = useSelector((state) => state.userGoogle)
  const { userInfo: userRegisterInfo } = useSelector(
    (state) => state.userRegister
  )

  const user = userGoogleInfo || userRegisterInfo || userInfo

  const userId = window.localStorage.getItem('USER_INFO')
    ? JSON.parse(window.localStorage.getItem('USER_INFO'))._id
    : false

  const handleClick = () => {
    document.querySelector('.home-tab').click()
  }

  // add header shadow on scroll
  window.addEventListener('scroll', () => {
    let scrollPosition = window.scrollY

    if (document.querySelector('.app-header')) {
      if (scrollPosition > 0) {
        document.querySelector('.app-header').classList.add('active')
      } else {
        document.querySelector('.app-header').classList.remove('active')
      }
    }
  })

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  // Update active tab if page is changed from footer nav
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    const list = document.querySelectorAll('.app-header .nav-link')

    list.forEach((el) => {
      if (location.pathname !== el.getAttribute('href')) {
        el.classList.remove('active')
      }
    })
  }, [location])

  return (
    <header className='app-header'>
      <Navbar
        collapseOnSelect
        expand='xl'
        bg='light'
        variant='light'
        className='px-sm-0 py-0'
      >
        <Container className='p-0 px-4 border-bottom'>
          <div className='quick-address'>
            <a
              href='https://goo.gl/maps/R8uCoRUEcGDCKci69'
              target='_blank'
              rel='noopener noreferrer'
            >
              <FaMapMarkedAlt />
              <span>Адрес</span>
            </a>
          </div>

          <div className='quick-contact'>
            <a href='tel:+359896922993' className='mb-0 pb-2 d-block'>
              <FaPhoneAlt style={{ fontSize: '0.8em', lineHeight: 1 }} />
              <span>0896 922 993</span>
            </a>
          </div>

          <LinkContainer to='/home' onClick={handleClick}>
            <Navbar.Brand className='app-logo py-0'>
              <img
                src='/images/campic-logo-new-dynamic.svg'
                alt='campic-logo'
              />
              <h1 className='app-slogan'>РЕМОНТ НА КЕМПЕРИ И КАРАВАНИ</h1>
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='ml-auto main-nav'>
              {routes
                .filter((r) => !r.footer)
                .map((route, i) =>
                  route.to === '/' ? (
                    <LinkContainer key={i} to={route.to} exact>
                      <Nav.Link>{route.title}</Nav.Link>
                    </LinkContainer>
                  ) : (
                    <>
                      {route.to === '/shop' ? (
                        <a
                          href='https://campicbg.eplace.bg/'
                          target='_blank'
                          rel='noopener noreferrer'
                          className='nav-link'
                        >
                          {route.title}
                        </a>
                      ) : (
                        <LinkContainer key={i} to={route.to}>
                          <Nav.Link>{route.title}</Nav.Link>
                        </LinkContainer>
                      )}
                    </>
                  )
                )}

              {user && user.name ? (
                <NavDropdown
                  title={
                    <OverlayTrigger
                      placement='right'
                      overlay={
                        <Tooltip
                          className='ml-4'
                          style={{ fontFamily: 'Montserrat' }}
                          id={`tooltip-name`}
                        >
                          Здравей, <br></br> {user.name}
                        </Tooltip>
                      }
                    >
                      <span>
                        {user.isAdmin ? (
                          <BsShieldShaded className='login-admin-ico' />
                        ) : (
                          <FaUserCircle className='login-user-ico' />
                        )}{' '}
                        <SliceText
                          text={user.name}
                          tag='span'
                          length='12'
                          shade={false}
                          isSanitized={false}
                        />
                      </span>
                    </OverlayTrigger>
                  }
                  id='username'
                >
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>
                      <FaCog className='mr-3' />
                      Профил
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    <FaSignOutAlt className='mr-2' /> Изход
                  </NavDropdown.Item>
                </NavDropdown>
              ) : !userId ? (
                <LinkContainer to='/login'>
                  <Nav.Link className='pr-0'>
                    <RiUserShared2Fill className='login-ico' /> Вход
                  </Nav.Link>
                </LinkContainer>
              ) : (
                <LoaderSmall />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
