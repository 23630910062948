import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPagesInfo } from '../../redux/actions/pageActions'

const PageInfo = () => {
  const dispatch = useDispatch()

  const pageUpdate = useSelector((state) => state.pageUpdate)
  const { success } = pageUpdate

  useEffect(() => {
      dispatch(getPagesInfo())
  }, [dispatch, success])

  return <></>
}

export default PageInfo
