import '../sass/components/PolicyAlert.scss'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'

const PolicyAlert = () => {
  const USER_INFO = JSON.parse(window.localStorage.getItem('USER_INFO'))
  const [cookies, setCookies] = useState(
    USER_INFO && USER_INFO.policy && USER_INFO.policy.cookies
      ? USER_INFO.policy.cookies
      : false
  )

  const { userInfo } = useSelector((state) => state.userLogin)

    const onClick = (e) => {
      e.preventDefault()

      if (!window.localStorage.getItem('USER_INFO')) {
        window.localStorage.setItem(
          'USER_INFO',
          JSON.stringify({
            policy: {
              cookies: 'accepted',
            },
          })
        )
      }
      setCookies(true)
    }

  useEffect(() => {
    if(userInfo) {
      setCookies(true)
    }
  }, [userInfo, cookies])

  return (
    <>
      {(!cookies && userInfo === null) && (
        <section className='policy-alert py-8 d-flex align-items-center'>
          <Container>
            <Row>
              <Col lg={9}>
                <p className='p-0'>
                  <Link className='campic-quote' to='/about'>
                    <strong>Campic-bg.com</strong>
                  </Link>{' '}
                  използва “бисквитки“.<br></br>
                  Като кликнете върху бутона “Приемам“, вие се съгласявате с
                  използването на бисквитките.<br></br>
                  За да научите повече, моля вижте нашата{' '}
                  <Link className='cookie-policy-btn' to='/cookies'>
                    Политика за бисквитки
                  </Link>
                </p>
              </Col>
              <Col lg={3} className='text-lg-center'>
                <Button
                  onClick={onClick}
                  className='mt-6 mt-lg-1 btn btn-warning px-10'
                  type='button'
                >
                  Приемам
                </Button>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}

export default PolicyAlert
