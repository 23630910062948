import { ALERT_ACTIVE, ALERT_RESET } from "../constants/alertConstants"

export const alertReducer = (state = {message: ''}, action) => {
  switch (action.type) {
    case ALERT_ACTIVE:
      return { message: action.payload }
    case ALERT_RESET:
      return { message: 'n/a' }
    default:
      return state
  }
}