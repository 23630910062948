import { lazy, useEffect } from 'react'
import './sass/App.scss';
import { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ReactGA from 'react-ga';

import Loader from './components/Loader'

import PageInfo from './components/common/PageInfo'
import UserInfo from './components/common/UserInfo'

import Header from './components/Header'
import Footer from './components/Footer'

import Alert from './components/Alert'
import PolicyAlert from './components/PolicyAlert'
import GoTopButton from './components/GoTopButton'

const HomePage = lazy(() => import('./pages/HomePage'))
const AboutPage = lazy(() => import('./pages/AboutPage'))
const ServicesPage = lazy(() => import('./pages/ServicesPage'))
const RepairsPage = lazy(() => import('./pages/RepairsPage'))
// const OffersPage = lazy(() => import('./pages/OffersPage'))
const ContactsPage = lazy(() => import('./pages/ContactsPage'))
const BlogPage = lazy(() => import('./pages/BlogPage'))
const PostPage = lazy(() => import('./pages/PostPage'))
const LoginPage = lazy(() => import('./pages/LoginPage'))
const SecondFactorPage = lazy(() => import('./pages/SecondFactorPage'))
const RegisterPage = lazy(() => import('./pages/RegisterPage'))
const RegisterConfirm = lazy(() => import('./pages/RegisterConfirm'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'))
const PolicyCookies = lazy(() => import('./pages/PolicyCookies'))
const PolicyPrivacy = lazy(() => import('./pages/PolicyPrivacy'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'))

function App() {

  const AlertState = useSelector((state) => state.alert)

  ReactGA.initialize([
    {
      trackingId: 'UA-197599514-1',
    },
    {
      trackingId: 'UA-197059104-1',
    },
  ])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  return (
    <>
      <PageInfo />
      <UserInfo />

      <Router>
        <Header />
        <main className='app-main'>
          <Suspense
            fallback={
              <section className='page-load'>
                <Loader />
              </section>
            }
          >
            <Switch>
              <Route path='/about' component={AboutPage} />
              <Route path='/services' component={ServicesPage} />
              <Route path='/repairs' component={RepairsPage} exact />
              <Route path='/repairs/:q' component={RepairsPage} exact />
              {/* <Route path='/offers' component={OffersPage} /> */}
              <Route path='/contacts' component={ContactsPage} />
              <Route path='/blog' component={BlogPage} />
              <Route path='/blog/:q' component={BlogPage} exact />
              <Route path='/post/:id' component={PostPage} exact />
              <Route path='/login' component={LoginPage} />
              <Route path='/2fa' component={SecondFactorPage} />
              <Route path='/register-confirm/:token' component={RegisterConfirm} />
              <Route path='/register' component={RegisterPage} exact />
              <Route path='/forgot-password' component={ForgotPassword} />
              <Route path='/profile' component={UserProfilePage} />
              <Route path='/cookies' component={PolicyCookies} />
              <Route path='/privacy' component={PolicyPrivacy} />
              <Route path='/home' component={HomePage} exact />
              <Route path='/#' component={HomePage} exact />
              <Route path='/' component={HomePage} exact />
              <Route path='*' component={NotFoundPage} />
            </Switch>
          </Suspense>
        </main>

        {AlertState.message && AlertState.message !== 'n/a' && (
          <Alert AlertState={AlertState} />
        )}

        <PolicyAlert />
        <GoTopButton />

        <MessengerCustomerChat
          pageId='104569478392160'
          appId='319270922904412'
          language='bg_BG'
          themeColor='#f4772c'
        />

        <Footer />
      </Router>
    </>
  )
}

export default App;
