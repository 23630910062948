export const routes = [
  {
    title: 'Начало',
    to: '/',
  },
  {
    title: 'За мен',
    to: '/about',
  },
  {
    title: 'Услуги',
    to: '/services',
  },
  {
    title: 'Ремонти',
    to: '/repairs',
  },
  {
    title: 'Е-магазин',
    to: '/shop',
  },
  {
    title: 'Блог',
    to: '/blog',
  },
  {
    title: 'Контакти',
    to: '/contacts',
  },
  {
    title: 'Поверителност',
    to: '/privacy',
    footer: true,
  },
  {
    title: ' Бисквитки',
    to: '/cookies',
    footer: true,
  },
]
