import {
  GET_REPAIR_REQUEST,
  GET_REPAIR_SUCCESS,
  GET_REPAIR_FAIL,
  REPAIR_SAVE_REQUEST,
  REPAIR_SAVE_SUCCESS,
  REPAIR_SAVE_FAIL,
  REPAIR_SAVE_RESET,
  REPAIR_DELETE_REQUEST,
  REPAIR_DELETE_SUCCESS,
  REPAIR_DELETE_FAIL,
} from '../constants/repairConstants'

export const getRepairInfoReducer = (
  state = { loading: true, repairs: [] },
  action
) => {
  switch (action.type) {
    case GET_REPAIR_REQUEST:
      return {
        loading: true,
        repairs: [],
      }
    case GET_REPAIR_SUCCESS:
      return {
        loading: false,
        repairs: action.payload.repairs,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case GET_REPAIR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const saveRepairReducer = (state = { repair: {} }, action) => {
  switch (action.type) {
    case REPAIR_SAVE_REQUEST:
      return { loading: true }
    case REPAIR_SAVE_SUCCESS:
      return { loading: false, success: true, repair: action.payload }
    case REPAIR_SAVE_FAIL:
      return { loading: false, error: action.payload }
    case REPAIR_SAVE_RESET:
      return { repair: {} }
    default:
      return state
  }
}

export const repairDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REPAIR_DELETE_REQUEST:
      return { loading: true }
    case REPAIR_DELETE_SUCCESS:
      return { loading: false, success: true }
    case REPAIR_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
