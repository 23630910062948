export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST'
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
export const GET_POSTS_FAIL = 'GET_POSTS_FAIL'
export const GET_POST_REQUEST = 'GET_POST_REQUEST'
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS'
export const GET_POST_FAIL = 'GET_POST_FAIL'
export const GET_POST_RESET = 'GET_POST_RESET'
export const UPDATE_POST_VIEWS_REQUEST = 'UPDATE_POST_VIEWS_REQUEST'
export const UPDATE_POST_VIEWS_SUCCESS = 'UPDATE_POST_VIEWS_SUCCESS'
export const UPDATE_POST_VIEWS_FAIL = 'UPDATE_POST_VIEWS_FAIL'
export const POST_SAVE_REQUEST = 'POST_SAVE_REQUEST'
export const POST_SAVE_SUCCESS = 'POST_SAVE_SUCCESS'
export const POST_SAVE_FAIL = 'POST_SAVE_FAIL'
export const POST_SAVE_RESET = 'POST_SAVE_RESET'
export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST'
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS'
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL'
export const POST_DELETE_RESET = 'POST_DELETE_RESET'

export const ITEMS_PER_PAGE = 7


