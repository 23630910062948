import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { FaRegCopyright } from 'react-icons/fa'
import logo from '../assets/images/aleksov-logo.svg';
import '../sass/components/Footer.scss'
import { routes } from '../helpers/appConstants'
import { useSelector } from 'react-redux'
import {
  FaPhoneAlt,
  FaClock,
  FaRegClock,
  FaMapMarkedAlt,
} from 'react-icons/fa'
import { MdMail } from 'react-icons/md'

const currentYear = new Date().getFullYear();

const Footer = () => {

  // Consume Page Data
  const pagesInfo = useSelector((state) => state.pagesInfo)
  const { pages } = pagesInfo

  const contactsData = pages.find((p) => p.page === 'Contacts')

  return (
    <footer className='site-footer mt-auto py-0'>
      <div className='footer-logo-large'>
        <img src='/images/campic-logo.svg' alt='slider-title' />
      </div>

      <Container className='pb-0'>
        <Row className='py-5'>
          <Col md={4} className='widget widget-about'>
            <header>
              <h5>Campic</h5>
            </header>
            <div className='widget-content'>
              <p>
                <Link to='/services'>
                  Сервиз и поддръжка на кемпери и каравани. Съдействие, съвети и
                  проверка при покупката на кемпер или каравана
                </Link>
              </p>
            </div>
          </Col>

          <Col md={4} className='widget widget-sitemap'>
            <header>
              <h5>Карта на сайта</h5>
            </header>
            <div className='widget-content'>
              <ul>
                {routes.map((route, i) =>
                  route.to === '/' ? (
                    <li key={i}>
                      <Link to={route.to} exact>
                        {route.title}
                      </Link>
                    </li>
                  ) : (
                    <li key={i}>
                      <Link to={route.to}>{route.title}</Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          </Col>

          <Col md={4} className='widget widget-contacts'>
            <header>
              <h5>Контакти</h5>
            </header>
            {contactsData && (
              <div className='widget-content'>
                <ul>
                  <li>
                    <a className='d-flex' href={`tel:${contactsData.custom.contacts.phone.replaceAll(/\s/g,'')}`}>
                      <FaPhoneAlt style={{ minWidth: '40px' }} />
                      {contactsData.custom.contacts.phone}
                    </a>
                  </li>
                  <li>
                    <a className='d-flex' href={`mailto:${contactsData.custom.contacts.email}`}>
                      <MdMail style={{ minWidth: '40px', fontSize: '1.2em' }} />
                      {contactsData.custom.contacts.email}
                    </a>
                  </li>
                  <li>
                    <p className='d-flex pb-4'>
                      <FaClock style={{ minWidth: '40px' }} />
                      {contactsData.custom.time.worktime}
                    </p>
                    <p className='d-flex pb-0'>
                      <FaRegClock style={{ minWidth: '40px' }} />
                      {contactsData.custom.time.weekends}
                    </p>
                  </li>
                  <li>
                    <a
                      href='https://goo.gl/maps/R8uCoRUEcGDCKci69'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <address className='d-flex'>
                        <FaMapMarkedAlt
                          style={{
                            minWidth: '40px',
                            fontSize: '1.2em',
                            marginTop: '0',
                          }}
                        />
                        <span>{contactsData.custom.address}</span>
                      </address>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <div className='footer-end'>
        <Container>
          <Row className='mx-3'>
            <Col className='text-center py-2'>
              <FaRegCopyright className='copy-icon' /> 2020-{currentYear} Всички
              права запазени
              {` - ${process.env.REACT_APP_VERSION} изработка`}
              <a
                className='developer'
                href='https://alekshristov.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  alt='aleksov-logo'
                  src={logo}
                  style={{
                    height: '1.55em',
                    margin: '-0.2em 0 0 0.4em',
                    opacity: '0.9',
                  }}
                ></img>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
